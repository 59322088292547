import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import Content from '../../components/layouts/Content';
// import { ebooks } from '../../data/ebooks';
// import EbookBanner from '../../sessions/EbookBanner';
import FranchiseBanner from '../../components/FranchiseBanner';
import styles from './styles.module.scss';
import {
    IconDefinition,
    faLongArrowAltRight,
    faHome,
    faBookReader,
    faComments,
    faPlane,
    faBriefcaseMedical,
    faPlaneDeparture,
    faBriefcase,
} from '@fortawesome/free-solid-svg-icons';
import {
    faCcVisa,
    faFontAwesomeFlag,
} from '@fortawesome/free-brands-svg-icons';
import Jumbotron from '../../components/Jumbotron';
import ProgramSlider from '../../components/ProgramSlider';
import MotivationsTabs from '../../components/MotivationsTabs';
import {
    Form,
    FormGroup,
    Input,
    Label,
    SubmitButton,
} from '../../components/Forms';
import Checkbox from '../../components/Forms/Checkbox';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import postsSlice from '../../store/modules/posts';
import { useAppSelector } from '../../hooks/store';
import PostList from '../../components/PostList';
import Markdown from 'react-markdown';
import homeSlice from '../../store/modules/home';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import LoadingAndError, {
    InlineLoadingAndError,
} from '../../components/LoadingAndError';
import motivationsSlice from '../../store/modules/motivations';
import Select, { OptionProps } from 'react-select';
import Joi from 'joi';
import FormError from '../../components/Forms/FormError';
import { InputMask } from '../../components/Forms/Input';
import rdstationSlice from '../../store/modules/rdstation';
import { InlineLoading } from '../../components/Loading';
import FormSuccess from '../../components/Forms/FormSuccess';

interface Differential {
    icon: IconDefinition;
    description: string;
}

const differential: Differential[] = [
    { icon: faFontAwesomeFlag, description: 'Conexão em mais de 40 países' },
    { icon: faComments, description: 'Estudo de idiomas' },
    { icon: faHome, description: 'Acomodação para o seu intercâmbio' },
    { icon: faCcVisa, description: 'Planos de pagamento facilitado' },
    { icon: faPlane, description: 'Reserva de passagens aéreas' },
    { icon: faBriefcaseMedical, description: 'Seguro viagem' },
    { icon: faBookReader, description: 'Graduação' },
    { icon: faBriefcase, description: 'Carreira no exterior' },
];

const semesterOptions: OptionProps['options'] = [
    { value: '2º semestre de 2023', label: '2º semestre de 2023' },
    { value: '1º semestre de 2024', label: '1º semestre de 2024' },
    { value: '2º semestre de 2042', label: '2º semestre de 2024' },
];

const conversionSchema = Joi.object({
    name: Joi.string()
        .required()
        .messages({ 'string.empty': 'O nome é obrigatório' }),
    phone: Joi.string().required().messages({
        'string.empty': 'O telefone é obrigatório',
        'any.required': 'O telefone é obrigatório',
    }),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
            'string.empty': 'O email é obrigatório',
            'string.email': 'O email deve ser válido',
        }),
    interests: Joi.array().items(
        Joi.string().valid(
            // permissões cadastradas no RD Station
            'estudar-um-idioma',
            'estudar-e-trabalhar',
            'emprego-melhor',
            'imigrar',
        ),
    ),
    countries: Joi.array().items(
        Joi.string().valid(
            // permissões cadastradas no RD Station
            'canada',
            'irlanda',
            'australia',
            'nova-zelandia',
            'reino-unido',
            'outros',
        ),
    ),
    departPeriod: Joi.string().optional(),
});

const HomePage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [formData, setFormData] = useState<{ [name: string]: any }>({
        name: '',
        phone: undefined,
        email: '',
        interests: [],
        countries: [],
        departPeriod: undefined,
    });
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        dispatch(postsSlice.thunks.fetchAll());
        dispatch(homeSlice.thunks.fetch());
        dispatch(motivationsSlice.thunks.fetchAll());
    }, []);

    const posts = useAppSelector(postsSlice.selectors.select6);
    const postListState = useAppSelector(
        postsSlice.selectors.selectListAsyncState,
    );

    const motivations = useAppSelector(motivationsSlice.selectors.selectAll);
    const motivationListState = useAppSelector(
        motivationsSlice.selectors.selectListAsyncState,
    );

    const homeState = useAppSelector(homeSlice.selectors.selectState);

    const { conversion } = useAppSelector(rdstationSlice.selectors.selectState);

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (
        e,
    ) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = (
        event,
    ) => {
        const { name, checked, dataset } = event.target;
        const { group } = dataset;
        if (!group) {
            console.error(`Should have a group for ${name} checkbox`);
            return;
        }
        const array = formData[group];
        if (checked) {
            array.push(name);
        } else {
            const index = array.indexOf(name);
            if (index > -1) array.splice(index);
        }
        setFormData({
            ...formData,
            [group]: array,
        });
    };

    const handleConversionSubmit: React.FormEventHandler<HTMLFormElement> = (
        event,
    ) => {
        event.preventDefault();
        const validation = conversionSchema.validate(formData, {
            abortEarly: false,
        });
        if (validation.error) {
            setErrors(validation.error?.details.map((i) => i.message));
        } else {
            dispatch(rdstationSlice.thunks.conversion(validation.value));
        }
    };

    return (
        <>
            <Content className={styles.headerGrid}>
                <div>
                    <h1 className={styles.headerTitle}>
                        O que há de melhor nas viagens internacionais
                    </h1>
                    <div className={styles.headerDescription}>
                        Os melhores programas para cada destino, planejados
                        junto com você e um especialista
                    </div>
                </div>
                {homeState.data ? (
                    <ProgramSlider data={homeState.data.programs} />
                ) : (
                    <InlineLoadingAndError
                        condition={Boolean(homeState.data)}
                        loading={homeState.loading}
                        error={homeState.error}
                    />
                )}
            </Content>
            <Content className={styles.differentialsContainer}>
                {differential.map((option, index) => (
                    <div key={index} className={styles.differentialCard}>
                        <div className={styles.differentialIconContainer}>
                            <Icon
                                className={styles.differentialIcon}
                                icon={option.icon}
                            />
                        </div>
                        <span className={styles.differentialDescription}>
                            {option.description}
                        </span>
                    </div>
                ))}
            </Content>
            <Jumbotron className={styles.motivationsSession}>
                <h1 className={styles.motivationTitle}>
                    Qual a sua motivação?
                </h1>
                {motivations.length > 0 ? (
                    <MotivationsTabs data={motivations} />
                ) : (
                    <LoadingAndError
                        loading={motivationListState.loading}
                        error={motivationListState.error}
                    />
                )}
            </Jumbotron>
            <Jumbotron className={styles.formSession}>
                <div className={styles.formContainer}>
                    <h1 className={styles.formTitle}>
                        Entenda o percurso até o seu intercâmbio
                    </h1>
                    <div className={styles.timeline}>
                        <ul className={styles.timelineList}>
                            <li className={styles.timelineEvent}>
                                <h2 className={styles.stepTitle}>
                                    Pré-projeto
                                </h2>
                                <p className={styles.stepDescription}>
                                    Deixe o seu contato (sem compromisso) e suas
                                    preferências
                                </p>
                            </li>
                            <li className={styles.timelineEvent}>
                                <h2 className={styles.stepTitle}>Contato</h2>
                                <p className={styles.stepDescription}>
                                    Contato Nosso time de especialistas em
                                    intercâmbio vai entrar em contato com você.
                                    Vamos juntos planejar a sua viagem e tirar
                                    suas dúvidas
                                </p>
                            </li>
                            <li className={styles.timelineEvent}>
                                <h2 className={styles.stepTitle}>Escolha</h2>
                                <p className={styles.stepDescription}>
                                    Analise as opções enviadas e escolha a
                                    melhor opção de programa, destino e
                                    pagamento
                                </p>
                            </li>
                            <li className={styles.timelineEvent}>
                                <h2 className={styles.stepTitle}>Pré-viagem</h2>
                                <p className={styles.stepDescription}>
                                    Momento de conversarmos e tirar dúvidas
                                    sobre o embarque, a mala, o programa, o
                                    destino, o aeroporto… Tudo para tornar a sua
                                    viagem mais tranquila e a experiência ainda
                                    mais incrível
                                </p>
                            </li>
                            <li className={styles.timelineEvent}>
                                <h2 className={styles.stepTitle}>
                                    O grande dia
                                </h2>
                                <p className={styles.stepDescription}>
                                    Fique tranquilo. Estaremos com você durante
                                    toda a viagem
                                </p>
                            </li>
                        </ul>
                    </div>
                    <Icon className={styles.formIcon} icon={faPlaneDeparture} />
                    <Form
                        className={styles.form}
                        onSubmit={handleConversionSubmit}
                    >
                        <FormGroup className={styles.formGroup}>
                            <Label className={styles.formLabel} htmlFor="#name">
                                Nome
                            </Label>
                            <Input
                                id="name"
                                name="name"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup className={styles.formGroup}>
                            <Label
                                className={styles.formLabel}
                                htmlFor="#phone"
                            >
                                Telefone
                            </Label>
                            <InputMask
                                id="phone"
                                name="phone"
                                mask="(99) 99999-9999"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup className={styles.formGroup}>
                            <Label
                                className={styles.formLabel}
                                htmlFor="#email"
                            >
                                Email
                            </Label>
                            <Input
                                id="email"
                                name="email"
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup className={styles.formGroup}>
                            <Label className={styles.formLabel}>
                                Tenho interesse em
                            </Label>
                            <div className={styles.formInterestsGrid}>
                                <Checkbox
                                    name="estudar-um-idioma"
                                    label="Estudar um idioma"
                                    id="study-abroad"
                                    data-group="interests"
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    name="estudar-e-trabalhar"
                                    label="Estudar e trabalhar"
                                    id="study-and-work"
                                    data-group="interests"
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    name="emprego-melhor"
                                    label="Dar up na carreira"
                                    id="better-jobs"
                                    data-group="interests"
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    name="imigrar"
                                    label="Imigrar"
                                    id="immigrate"
                                    data-group="interests"
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className={styles.formGroup}>
                            <Label className={styles.formLabel}>
                                No(s) país(es)
                            </Label>
                            <div className={styles.formCountryGrid}>
                                <Checkbox
                                    name="canada"
                                    label="Canadá"
                                    id="canada"
                                    data-group="countries"
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    name="irlanda"
                                    label="Irlanda"
                                    id="irlanda"
                                    data-group="countries"
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    name="australia"
                                    label="Austrália"
                                    id="australia"
                                    data-group="countries"
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    name="nova-zelandia"
                                    label="Nova zelândia"
                                    id="zelandia"
                                    data-group="countries"
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    name="eua"
                                    label="EUA"
                                    id="eua"
                                    data-group="countries"
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    name="reino-unido"
                                    label="Reino Unido"
                                    id="united-kingdom"
                                    data-group="countries"
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    name="outros"
                                    label="Outros"
                                    id="others"
                                    data-group="countries"
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className={styles.formGroup}>
                            <Label className={styles.formLabel}>
                                Pretendo embarcar em:
                            </Label>
                            <div>
                                <Select
                                    name="departPeriod"
                                    options={semesterOptions}
                                    placeholder="Selecione"
                                    isSearchable={true}
                                    onChange={(newValue) => {
                                        const value = (
                                            newValue as { value: string }
                                        ).value;
                                        setFormData({
                                            ...formData,
                                            departPeriod: value,
                                        });
                                    }}
                                />
                            </div>
                        </FormGroup>
                        {errors.length > 0 &&
                            errors.map((err, i) => (
                                <FormError key={i} message={err} />
                            ))}
                        {conversion.error && (
                            <FormError message={conversion.error} />
                        )}
                        {conversion.status == 'success' && (
                            <FormSuccess message="Enviado!" />
                        )}
                        <SubmitButton
                            className={styles.submitButton}
                            type="submit"
                            loading={conversion.status == 'in-progress'}
                        >
                            Enviar
                        </SubmitButton>
                    </Form>
                </div>
            </Jumbotron>
            <Content>
                <PostList
                    data={posts}
                    loading={postListState.loading}
                    error={postListState.error}
                />
                <div className={styles.center}>
                    <Link className={styles.knowMore} to="/blog">
                        <Icon
                            className={styles.arrowIcon}
                            icon={faLongArrowAltRight}
                        />
                        Saiba mais
                    </Link>
                </div>
            </Content>
            {/* <EbookBanner data={ebooks[0]} /> */}
            <Content>
                <FranchiseBanner className={styles.franchiseSession} />

                <div className={styles.franchiseContent}>
                    {homeState.data ? (
                        <Markdown>
                            {homeState.data.franchiseDescription}
                        </Markdown>
                    ) : homeState.loading ? (
                        <Loading />
                    ) : (
                        homeState.error && <Error />
                    )}

                    <Link className={styles.knowMore} to="/franchise">
                        <Icon
                            className={styles.arrowIcon}
                            icon={faLongArrowAltRight}
                        />
                        Saiba mais
                    </Link>
                </div>
            </Content>
        </>
    );
};

export default HomePage;
