import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { RouteComponentProps } from 'react-router';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import Title from '../../components/Title';
import styles from './styles.module.scss';
import {
    faFacebook,
    faTwitter,
    faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import Content from '../../components/layouts/Content';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import postsSlice from '../../store/modules/posts';
import { useAppSelector } from '../../hooks/store';
import Markdown from 'react-markdown';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { InlineLoadingAndError } from '../../components/LoadingAndError';

type Props = RouteComponentProps<{ id: string; slug: string }>;

const PostPage: React.FC<Props> = (props) => {
    const dispatch = useDispatch<AppDispatch>();

    const { id } = props.match.params;

    useEffect(() => {
        dispatch(postsSlice.thunks.fetchOne(id));
    }, []);

    const post = useAppSelector((state) =>
        postsSlice.selectors.selectById(state, id),
    );

    const listState = useAppSelector(postsSlice.selectors.selectFindAsyncState);

    const findState = useAppSelector(postsSlice.selectors.selectFindAsyncState);

    const onClick = () => {
        props.history.goBack();
    };

    if (post) {
        return (
            <Content>
                <div className={styles.container}>
                    <Title>
                        Blog
                        <InlineLoadingAndError
                            loading={findState.loading}
                            error={findState.error}
                        />
                    </Title>
                    <div className={styles.post}>
                        <img
                            className={styles.thumbnail}
                            src={post.thumbnail.url}
                            alt={post.thumbnail.caption}
                        />
                        <div className={styles.header}>
                            <h1 className={styles.title}>{post.title}</h1>
                            <div className={styles.publicationDate}>
                                {format(
                                    new Date(post.publishedAt),
                                    "'Publicado em' dd 'de' MMMM 'de' yyyy",
                                )}
                            </div>
                        </div>
                        <Markdown className={styles.content}>
                            {post.content}
                        </Markdown>
                        <button className={styles.backButton} onClick={onClick}>
                            <Icon
                                className={styles.icon}
                                icon={faLongArrowAltLeft}
                            />
                            Voltar
                        </button>
                        <div className={styles.socialContainer}>
                            <div className={styles.shareTitle}>Compartilhe</div>
                            <div>
                                <button className={styles.socialButton}>
                                    <Icon
                                        className={styles.whatsappIcon}
                                        icon={faWhatsapp}
                                    />
                                </button>
                                <button className={styles.socialButton}>
                                    <Icon
                                        className={styles.facebookIcon}
                                        icon={faFacebook}
                                    />
                                </button>
                                <button className={styles.socialButton}>
                                    <Icon
                                        className={styles.twitterIcon}
                                        icon={faTwitter}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        );
    }

    if (listState.loading || findState.loading) return <Loading />;

    return <Error />;
};

export default PostPage;
