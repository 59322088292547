import React from 'react';
import styles from './styles.module.scss';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

function InlineError() {
    return <Icon className={styles.inlineIcon} icon={faExclamationTriangle} />;
}

function Error() {
    return (
        <div className={styles.container}>
            <Icon className={styles.icon} icon={faExclamationTriangle} />
            <h1 className={styles.text}>Algo deu errado</h1>
        </div>
    );
}

export { InlineError };
export default Error;
