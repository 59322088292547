import {
    createEntityAdapter,
    createSlice,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit';
import { AppState } from '../..';
import { moduleName, Category } from './types';
import thunks from './thunks';
import { ErrorState } from '../../utils/types';

const categoriesAdapter = createEntityAdapter<Category>({
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export interface CategoryState {
    data: EntityState<Category>;
    listAsync: {
        loading: boolean;
        error: ErrorState | false;
    };
}

const initialState: CategoryState = {
    data: categoriesAdapter.getInitialState(),
    listAsync: {
        loading: false,
        error: false,
    },
};

const { fetchAll } = thunks.actions;

const slice = createSlice({
    name: moduleName,
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAll.request]: (state) => {
            const { listAsync } = state;
            listAsync.loading = true;
            listAsync.error = false;
        },
        [fetchAll.success]: (state, action: PayloadAction<Category[]>) => {
            const { listAsync } = state;
            listAsync.loading = false;
            listAsync.error = false;
            categoriesAdapter.setAll(state.data, action.payload);
        },
        [fetchAll.failure]: (state, action: PayloadAction<ErrorState>) => {
            const { listAsync } = state;
            listAsync.loading = false;
            listAsync.error = action.payload;
        },
    },
});

// TODO: memoize selectors
const selectSlice = (state: AppState) => state[moduleName];

const customSelectors = {
    selectState: selectSlice,
    selectListAsyncState: (state: AppState) => selectSlice(state).listAsync,
};

const adapterSelectors = categoriesAdapter.getSelectors<AppState>(
    (state) => selectSlice(state).data,
);

const categoriesSlice = {
    thunks: thunks.creators,
    actions: { ...thunks.actions, ...slice.actions },
    selectors: { ...adapterSelectors, ...customSelectors },
};

export const reducer = slice.reducer;
export default categoriesSlice;
