import { Locale, Localization } from '../../utils/types';

export const moduleName = 'franchise';

export interface Franchise {
    id: number;
    title: string;
    content: string;
    createdAt: string;
    updatedAt: string;
    locale: Locale;
    localizations: Localization[];
}
