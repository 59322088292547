import { Locale, Localization } from '../../utils/types';
import { Program } from '../programs/types';

export const moduleName = 'motivations';

export interface Motivation {
    id: number;
    name: string;
    programs: Program[];
    createdAt: string;
    updatedAt: string;
    locale: Locale;
    localizations?: Localization[];
}
