import cmsApi from '../../../api/cms';
import thunkUtils from '../../utils/thunks';
import { moduleName } from './types';
import { mapFranchise } from './mapper';

const fetch = thunkUtils.createAsyncThunk(moduleName, async () => {
    const response = await cmsApi.get(`/${moduleName}`);
    return mapFranchise(await response.json());
});

const actions = {
    fetch: fetch.actions,
};

const creators = {
    fetch: fetch.thunkCreator,
};

const thunks = { actions, creators };

export default thunks;
