import React from 'react';
import styles from './styles.module.scss';
import Topbar from './Topbar';
import logo from '../../../assets/images/logo/logo.svg';
import Footer from './Footer';

const DefaultLayout: React.FC = ({ children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img
                    className={styles.logo}
                    src={logo}
                    alt="Logo da My intercâmbio"
                />
                <Topbar />
            </div>
            <div className={styles.contentContainer}>{children}</div>
            <Footer />
        </div>
    );
};

export default DefaultLayout;
