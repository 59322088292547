import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import AboutPage from '../pages/About';
import PostPage from '../pages/Post';
import BlogPage from '../pages/Blog';
import ContactPage from '../pages/Contact';
import FranchisePage from '../pages/Franchise';
import HomePage from '../pages/Home';
import ProgramPage from '../pages/Program';
import ProgramListPage from '../pages/ProgramList';
import LayoutRoute from './LayoutRoute';
import LandingPageLayout from '../components/layouts/LandingPageLayout';
import LandingPage from '../pages/LandingPage';

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <LayoutRoute exact path="/" component={HomePage} />
                <LayoutRoute path="/about" component={AboutPage} />
                <LayoutRoute path="/blog" component={BlogPage} exact />
                <LayoutRoute path="/blog/:id/:slug" component={PostPage} />
                <LayoutRoute path="/contact" component={ContactPage} />
                <LayoutRoute path="/franchise" component={FranchisePage} />
                <LayoutRoute
                    path="/programs"
                    component={ProgramListPage}
                    exact
                />
                <LayoutRoute
                    path="/programs/:id/:slug"
                    component={ProgramPage}
                />
                <LayoutRoute
                    path="/landing-page/:id/:slug"
                    component={LandingPage}
                    layout={LandingPageLayout}
                />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
