import React from 'react';
import Title from '../../components/Title';
import styles from './styles.module.scss';
import Content from '../../components/layouts/Content';
import FranchiseBanner from '../../components/FranchiseBanner';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import franchiseSlice from '../../store/modules/franchise';
import { useAppSelector } from '../../hooks/store';
import Markdown from 'react-markdown';
import LoadingAndError, {
    InlineLoadingAndError,
} from '../../components/LoadingAndError';

const FranchisePage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(franchiseSlice.thunks.fetch());
    }, []);

    const { data, loading, error } = useAppSelector(
        franchiseSlice.selectors.selectState,
    );

    return (
        <Content>
            <Title>
                Seja um franquiado
                <InlineLoadingAndError
                    condition={Boolean(data)}
                    loading={loading}
                    error={error}
                />
            </Title>

            {data ? (
                <>
                    <FranchiseBanner className={styles.franchiseSession} />
                    <h2 className={styles.title}>{data.title}</h2>
                    <Markdown>{data.content}</Markdown>
                </>
            ) : (
                <LoadingAndError loading={loading} error={error} />
            )}
        </Content>
    );

    return null;
};

export default FranchisePage;
