import React from 'react';
import styles from './styles.module.scss';

interface Props {
    message: string;
}

const FormSuccess: React.FC<Props> = ({ message }) => {
    return <div className={styles.container}>{message}</div>;
};

export default FormSuccess;
