import {
    createEntityAdapter,
    createSlice,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit';
import { AppState } from '../..';
import { moduleName, Contact } from './types';
import thunks from './thunks';
import { ErrorState } from '../../utils/types';

const contactsAdapter = createEntityAdapter<Contact>();

export interface ContactState {
    data: EntityState<Contact>;
    listAsync: {
        loading: boolean;
        error: ErrorState | false;
    };
}

const initialState: ContactState = {
    data: contactsAdapter.getInitialState(),
    listAsync: {
        loading: false,
        error: false,
    },
};

const { fetchAll } = thunks.actions;

const slice = createSlice({
    name: moduleName,
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAll.request]: (state) => {
            const { listAsync } = state;
            listAsync.loading = true;
            listAsync.error = false;
        },
        [fetchAll.success]: (state, action: PayloadAction<Contact[]>) => {
            const { listAsync } = state;
            listAsync.loading = false;
            listAsync.error = false;
            contactsAdapter.setAll(state.data, action.payload);
        },
        [fetchAll.failure]: (state, action: PayloadAction<ErrorState>) => {
            const { listAsync } = state;
            listAsync.loading = false;
            listAsync.error = action.payload;
        },
    },
});

// TODO: memoize selectors
const selectSlice = (state: AppState) => state[moduleName];

const customSelectors = {
    selectState: selectSlice,
    selectListAsyncState: (state: AppState) => selectSlice(state).listAsync,
};

const adapterSelectors = contactsAdapter.getSelectors<AppState>(
    (state) => selectSlice(state).data,
);

const contactsSlice = {
    thunks: thunks.creators,
    actions: { ...thunks.actions, ...slice.actions },
    selectors: { ...adapterSelectors, ...customSelectors },
};

export const reducer = slice.reducer;
export default contactsSlice;
