export const moduleName = 'rdstation';

export interface ConversionData {
    name: string;
    phone: string;
    email: string;
    interests: string[];
    countries: string[];
    departPeriod: string;
}
