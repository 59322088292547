import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const moduleName = 'async';

interface AsyncState {
    [index: string]: {
        loading: boolean;
        error: boolean;
    };
}

const initialState: AsyncState = {};

// TODO: Converts all loading and error state to this slice
const asyncSlice = createSlice({
    name: moduleName,
    initialState,
    reducers: {
        request(state, { payload }: PayloadAction<{ asyncId: string }>) {
            state[payload.asyncId] = {
                loading: true,
                error: false,
            };
        },
        success(state, { payload }: PayloadAction<{ asyncId: string }>) {
            state[payload.asyncId] = {
                loading: false,
                error: false,
            };
        },
        failure(state, { payload }: PayloadAction<{ asyncId: string }>) {
            state[payload.asyncId] = {
                loading: false,
                error: true,
            };
        },
    },
});

const { actions, reducer } = asyncSlice;

export { actions, reducer };
export default asyncSlice;
