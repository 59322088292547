import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import Title from '../../components/Title';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import {
    Form,
    Input,
    Label,
    SubmitButton,
    TextArea,
} from '../../components/Forms';
import Content from '../../components/layouts/Content';
import Markdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import contactsSlice from '../../store/modules/contacts';
import { useAppSelector } from '../../hooks/store';
import LoadingAndError from '../../components/LoadingAndError';

// const contacts = [
//     {
//         title: 'Maceió/AL - Brasil',
//         description:
//             'Sede América do Sul\n\n' +
//             'Rua Professora Esther Costa de Barros, 488, Galeria LV\n\n' +
//             'Jatiuca Maceió - AL 57036-080\n\n' +
//             'Telefone (82) 3028-009\n\n',
//     },
// ];

const ContactPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(contactsSlice.thunks.fetchAll());
    }, []);

    const contacts = useAppSelector(contactsSlice.selectors.selectAll);

    const contactsListState = useAppSelector(
        contactsSlice.selectors.selectListAsyncState,
    );

    return (
        <Content>
            <Title>Contato</Title>
            <div className={styles.grid}>
                <div className={styles.locationContainer}>
                    <LoadingAndError
                        condition={contacts.length === 0}
                        loading={contactsListState.loading}
                        error={contactsListState.error}
                    />
                    {contacts.map((contact, index) => (
                        <div key={index} className={styles.location}>
                            <Icon
                                className={styles.icon}
                                icon={faMapMarkerAlt}
                            />
                            <div className={styles.infoContainer}>
                                <h2 className={styles.title}>
                                    {contact.title}
                                </h2>
                                <Markdown>{contact.description}</Markdown>
                            </div>
                        </div>
                    ))}
                </div>
                <Form action="" className={styles.form}>
                    <div className={styles.formGroup}>
                        <Label>Nome</Label>
                        <Input name="name" />
                    </div>
                    <div className={styles.formGroup}>
                        <Label>Telefone</Label>
                        <Input name="phone" />
                    </div>
                    <div className={styles.formGroup}>
                        <Label>Email</Label>
                        <Input name="email" />
                    </div>
                    <div className={styles.formGroup}>
                        <Label>Mensagem</Label>
                        <TextArea name="messaege" rows={4} />
                    </div>
                    <SubmitButton>Enviar</SubmitButton>
                </Form>
            </div>
        </Content>
    );
};

export default ContactPage;
