import { mapLocalization } from '../../utils/mappers';
import { Locale, LocalizationResponse } from '../../utils/types';
import { Contact } from './types';

export interface ContactResponse {
    id: string;
    title: string;
    description: string;
    created_at: string;
    updated_at: string;
    locale: Locale;
    localizations?: LocalizationResponse[];
}

function mapContact(contact: ContactResponse): Contact {
    return {
        id: Number(contact.id),
        title: contact.title,
        description: contact.description,
        createdAt: contact.created_at,
        updatedAt: contact.updated_at,
        locale: contact.locale,
        localizations: contact.localizations?.map(mapLocalization),
    };
}

export { mapContact };
