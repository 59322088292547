import { configureStore } from '@reduxjs/toolkit';
import { reducer as posts } from './modules/posts';
import { reducer as about } from './modules/about';
import { reducer as franchise } from './modules/franchise';
import { reducer as programs } from './modules/programs';
import { reducer as async } from './modules/async';
import { reducer as categories } from './modules/categories';
import { reducer as home } from './modules/home';
import { reducer as motivations } from './modules/motivations';
import { reducer as contacts } from './modules/contacts';
import { reducer as ebooks } from './modules/ebooks';
import { reducer as rdstation } from './modules/rdstation';

/**
 * Store
 */
const store = configureStore({
    reducer: {
        posts,
        about,
        franchise,
        programs,
        async,
        categories,
        home,
        motivations,
        contacts,
        ebooks,
        rdstation,
    },
});

/**
 * Application and Dispatch state types
 */
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
