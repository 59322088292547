import classNames from 'classnames';
import React from 'react';
import { useState } from 'react';
import styles from './styles.module.scss';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import Markdown from 'react-markdown';
import { Motivation } from '../../store/modules/motivations/types';

interface Props {
    data: Motivation[];
}

// TODO: Convert MotivationTabs to generic tabs component
const MotivationsTabs: React.FC<Props> = ({ data }) => {
    const [tab, setTab] = useState(0);

    const selectedTab = data[tab];

    function selectTab(id: number) {
        const newTab = data.findIndex((motivation) => motivation.id === id);
        setTab(newTab);
    }

    return (
        <>
            <div className={styles.tabBar}>
                {data.map((motivation, index) => (
                    <button
                        key={index}
                        className={classNames({
                            [styles.tabName]: true,
                            [styles.selected]: index === tab,
                        })}
                        onClick={() => selectTab(motivation.id)}
                    >
                        {motivation.name}
                    </button>
                ))}
            </div>
            <div className={styles.tabContainer}>
                {selectedTab?.programs.map((program, index) => (
                    <div key={index} className={styles.motivationContainer}>
                        <Icon
                            className={styles.motivationIcon}
                            icon={faLongArrowAltRight}
                        />
                        <Markdown className={styles.motivationText}>
                            {program.title}
                        </Markdown>
                    </div>
                ))}
            </div>
        </>
    );
};

export default MotivationsTabs;
