import { cmsConfig } from '../../api/cms';
import {
    Country,
    CountryResponse,
    Image,
    ImageFormat,
    ImageFormatResponse,
    ImageResponse,
    Localization,
    LocalizationResponse,
    Package,
    PackageResponse,
} from './types';

function mapLocalization(localization: LocalizationResponse): Localization {
    return {
        id: Number(localization.id),
        locale: localization.locale,
        publishedAt: localization.published_at,
    };
}

function mapImageFormat(format: ImageFormatResponse): ImageFormat {
    return {
        name: format.name,
        hash: format.hash,
        ext: format.ext,
        mime: format.mime,
        width: Number(format.width),
        heigh: Number(format.heigh),
        size: Number(format.size),
        path: format.path,
        url: cmsConfig.baseUrl + format.url,
    };
}

function mapImage(image: ImageResponse): Image {
    return {
        id: Number(image.id),
        name: image.name,
        caption: image.caption,
        width: Number(image.width),
        height: Number(image.height),
        hash: image.hash,
        ext: image.ext,
        mime: image.mime,
        size: Number(image.size),
        url:
            process.env.NODE_ENV == 'production'
                ? image.url
                : cmsConfig.baseUrl + image.url,
        formats: Object.entries(image.formats).reduce<Image['formats']>(
            (formats, [format, data]) => {
                if (data) formats[format] = mapImageFormat(data);
                return formats;
            },
            {},
        ),
        createdAt: image.created_at,
        updatedAt: image.updated_at,
    };
}

function mapCountry(country: CountryResponse): Country {
    return {
        id: Number(country.id),
        name: country.name,
        icon: mapImage(country.icon),
        description: country.description,
        shortDescription: country.short_description,
        capital: country.capital,
        language: country.language,
        timezone: country.time_zone,
        currency: country.currency,
        voltage: country.voltage,
        gallery: country.gallery.map(mapImage),
        createdAt: country.created_at,
        updatedAt: country.updated_at,
        locale: country.locale,
    };
}

function mapPackage(pack: PackageResponse): Package {
    return {
        id: Number(pack.id),
        includedItens: pack.included_itens,
        currency: pack.currency,
        price: Number(pack.price),
        installmentQuantity: Number(pack.installment_quantity),
        installmentValue: Number(pack.installment_value),
        priceDescription: pack.price_description,
        locale: pack.locale,
        createdAt: pack.created_at,
        updatedAt: pack.updated_at,
    };
}

export { mapLocalization, mapImageFormat, mapImage, mapCountry, mapPackage };
