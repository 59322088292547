import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

function Topbar() {
    const [topbarWidth, setTopbarWidth] = useState(0);

    const topbarRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        setTopbarWidth(topbarRef.current?.offsetWidth || 0);
    }, [topbarRef]);

    return (
        <>
            <div ref={topbarRef} className={styles.topbar}>
                <Link className={styles.item} to="/">
                    Home
                </Link>
                <Link className={styles.item} to="/about">
                    Sobre
                </Link>
                <Link className={styles.item} to="/programs">
                    Programas
                </Link>
                <Link className={styles.item} to="/blog">
                    Blog
                </Link>
                <Link className={styles.item} to="/contact">
                    Contato
                </Link>
            </div>
            <div
                id="background-page"
                className={styles.backgroundPage}
                style={{ width: topbarWidth }}
            />
        </>
    );
}

export default Topbar;
