import React from 'react';
import styles from './styles.module.scss';
import image from '../../assets/images/franchise-image.svg';
import classNames from 'classnames';

interface Props {
    className?: string;
}

const FranchiseBanner: React.FC<Props> = ({ className }) => {
    return (
        <div className={classNames(styles.imageContainer, className)}>
            <img
                className={styles.image}
                src={image}
                alt="Seja um franqueado"
            />
            <div className={styles.description}>Seja um franqueado...</div>
        </div>
    );
};

export default FranchiseBanner;
