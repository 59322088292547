import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '../..';
import { moduleName } from './types';
import thunks from './thunks';

export interface RDStationState {
    conversion: {
        status: 'idle' | 'in-progress' | 'failure' | 'success';
        error?: string;
    };
}

const initialState: RDStationState = {
    conversion: {
        status: 'idle',
    },
};

const { conversion: conversionActions } = thunks.actions;

const slice = createSlice({
    name: moduleName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(conversionActions.request, (state) => {
            state.conversion.status = 'in-progress';
            state.conversion.error = undefined;
        });
        builder.addCase(conversionActions.success, (state) => {
            state.conversion.status = 'success';
        });
        builder.addCase(conversionActions.failure, (state, payload: any) => {
            state.conversion.status = 'failure';
            state.conversion.error = payload.error.message;
            console.log(state.conversion.error);
        });
    },
});

const selectors = {
    selectState: (state: AppState) => state[moduleName],
};

const rdstationSlice = {
    thunks: thunks.creators,
    actions: { ...thunks.actions, ...slice.actions },
    selectors,
};

export const reducer = slice.reducer;
export default rdstationSlice;
