import React from 'react';
import styles from './styles.module.scss';
import logo from '../../../assets/images/logo/logo.svg';
import logoOutline from '../../../assets/images/logo/logo-outline.svg';
import instagramIcon from '../../../assets/images/social/instagram.svg';
import facebookIcon from '../../../assets/images/social/facebook.svg';
import twitterIcon from '../../../assets/images/social/twitter.svg';
import { Link } from 'react-router-dom';

const LandingPageLayout: React.FC = ({ children }) => {
    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <img
                    className={styles.logo}
                    src={logo}
                    alt="Logo da My intercâmbio"
                />
            </header>
            <section className={styles.content}>{children}</section>
            <footer className={styles.footer}>
                <img
                    className={styles.logoOutline}
                    src={logoOutline}
                    alt="Logo da MY intercâmbio"
                />
                <p className={styles.contactText}>
                    +55 (82) 9 9999.8888 / 9988.7777
                    <br />
                    contato@myintercambio.com.br
                </p>
                <div className={styles.socialIcons}>
                    <Link to="/instagram">
                        <img
                            className={styles.icon}
                            src={instagramIcon}
                            alt="Instagram"
                        />
                    </Link>
                    <Link to="/facebook">
                        <img
                            className={styles.icon}
                            src={facebookIcon}
                            alt="Facebook"
                        />
                    </Link>
                    <Link to="/twitter">
                        <img
                            className={styles.icon}
                            src={twitterIcon}
                            alt="Twitter"
                        />
                    </Link>
                </div>
            </footer>
        </div>
    );
};

export default LandingPageLayout;
