import React, { useState } from 'react';
import { Program } from '../../store/modules/programs/types';
import ProgramCard from '../ProgramCard';
import styles from './styles.module.scss';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import {
    AnimatePresence,
    AnimateSharedLayout,
    motion,
    Variants,
} from 'framer-motion';

const reorderPrograms = (programs: Program[], page: number) => {
    page = Math.trunc(page);
    return programs.slice(page).concat(programs.slice(0, page));
};

interface Props {
    data: Program[];
}

const ProgramSlider: React.FC<Props> = ({ data }) => {
    const [page, setPage] = useState(0);

    const programs = reorderPrograms(data, page).slice(0);
    // Always removes one element of the list, forcing React to unmount
    // offset program
    programs.pop();

    function goToPrevPage() {
        setPage((page) => (data.length + page - 1) % data.length);
    }

    function goToNextPage() {
        setPage((page) => (page + 1) % data.length);
    }

    const variants: Variants = {
        initial: {
            x: 400,
            opacity: 0,
            transition: { duration: 1 },
        },
        normal: {
            x: 0,
            opacity: 1,
        },
        offset: {
            scale: 0.7,
            opacity: 0.4,
            x: [0, -150],
        },
        exit: {
            opacity: 0,
            scale: 0,
        },
    };

    return (
        <div className={styles.container}>
            <AnimateSharedLayout>
                <AnimatePresence>
                    {programs.map((program, index) => (
                        <motion.div
                            key={program.id}
                            className={classNames(styles.cardContainer, {
                                [styles.programOffset]: index === 0,
                            })}
                            layout
                            initial="initial"
                            animate={index === 0 ? 'offset' : 'normal'}
                            exit="exit"
                            variants={variants}
                            transition={{ duration: 0.5 }}
                        >
                            <ProgramCard data={program} />
                        </motion.div>
                    ))}
                </AnimatePresence>
            </AnimateSharedLayout>
            <div className={styles.pageButtons}>
                <button className={styles.prevButton} onClick={goToPrevPage}>
                    <Icon icon={faChevronLeft} />
                </button>
                <button className={styles.nextButton} onClick={goToNextPage}>
                    <Icon icon={faChevronRight} />
                </button>
            </div>
        </div>
    );
};

export default ProgramSlider;
