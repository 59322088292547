import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import CountrySession from '../../sessions/CountrySession';
import Jumbotron from '../../components/Jumbotron';
import { FormGroup, Input, Label, SubmitButton } from '../../components/Forms';
import ReCAPTCHA from 'react-google-recaptcha';
import Content from '../../components/layouts/Content';
import Markdown from 'react-markdown';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import programsSlice from '../../store/modules/programs';
import { useAppSelector } from '../../hooks/store';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import { InlineLoadingAndError } from '../../components/LoadingAndError';

type Props = RouteComponentProps<{ id: string; slug: string }>;

const LandingPage: React.FC<Props> = (props) => {
    const { id } = props.match.params;

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(programsSlice.thunks.fetchOne(id));
    }, []);

    const program = useAppSelector((state) =>
        programsSlice.selectors.selectById(state, id),
    );

    const listState = useAppSelector(
        programsSlice.selectors.selectFindAsyncState,
    );

    const findState = useAppSelector(
        programsSlice.selectors.selectFindAsyncState,
    );

    if (program) {
        const { country, package: pack } = program;

        if (typeof country !== 'object' || typeof pack !== 'object')
            return <Error />;

        return (
            <>
                <Jumbotron className={styles.headerContainer}>
                    <img
                        className={styles.backgroundImage}
                        src={program.thumbnail.url}
                        alt={program.thumbnail.caption}
                    />
                    <h1 className={styles.title}>
                        {program.title}
                        <InlineLoadingAndError
                            loading={findState.loading}
                            error={findState.error}
                        />
                    </h1>
                    <div className={styles.headerGrid}>
                        <div className={styles.videoContainer}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: program.videoIframe,
                                }}
                            />
                            <h2 className={styles.paymentText}>
                                Em {pack.installmentQuantity} de{' '}
                                {new Intl.NumberFormat('pt-br', {
                                    style: 'currency',
                                    currency: pack.currency,
                                }).format(pack.installmentValue)}{' '}
                                ou{' '}
                                {new Intl.NumberFormat('pt-br', {
                                    style: 'currency',
                                    currency: pack.currency,
                                }).format(pack.price)}{' '}
                                à vista
                            </h2>
                        </div>
                        <form className={styles.form}>
                            <FormGroup>
                                <Label htmlFor="#name">Nome</Label>
                                <Input id="name" name="name" />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="#phone">Telefone</Label>
                                <Input id="phone" name="phone" />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="#email">Email</Label>
                                <Input id="email" name="email" />
                            </FormGroup>
                            {/* TODO: Generate key fo reCAPTCHA */}
                            <div className={styles.recaptcha}>
                                <ReCAPTCHA sitekey="test" />
                            </div>
                            <SubmitButton className={styles.submitButton}>
                                Quero saber mais
                            </SubmitButton>
                        </form>
                    </div>
                </Jumbotron>
                <Content>
                    <Markdown className={styles.includedItens}>
                        {pack.includedItens}
                    </Markdown>
                </Content>
                <CountrySession
                    className={styles.countrySession}
                    data={country}
                />
            </>
        );
    }

    if (listState.loading || findState.loading) return <Loading />;

    return <Error />;
};

export default LandingPage;
