import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const Background: React.FC = ({ children }) => {
    const setRenderState = useState(false)[1];

    const element = document.getElementById('background-page');

    function renderBackground() {
        if (!element) {
            setRenderState(true);
        }
    }

    useEffect(() => {
        window.addEventListener('load', renderBackground);
        window.addEventListener('resize', renderBackground);
        return () => {
            window.removeEventListener('load', renderBackground);
            window.removeEventListener('resize', renderBackground);
        };
    }, []);

    if (element) {
        return ReactDOM.createPortal(children, element);
    }

    return null;
};

export default Background;
