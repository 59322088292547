import { Locale, Localization } from '../../utils/types';

export const moduleName = 'categories';

export interface Category {
    id: number;
    name: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
    locale: Locale;
    localizations?: Localization[];
}
