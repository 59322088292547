import React from 'react';
import styles from './styles.module.scss';

type InputDefaultProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

type Props = Omit<InputDefaultProps, 'type'> & {
    label: string;
    name?: string;
};

const Checkbox: React.FC<Props> = ({ label, ...restProps }) => {
    return (
        <label className={styles.container}>
            <input type="checkbox" {...restProps} />
            <div className={styles.text}>{label}</div>
        </label>
    );
};

export default Checkbox;
