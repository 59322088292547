import {
    createEntityAdapter,
    createSlice,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit';
import { AppState } from '../..';
import { moduleName, Program } from './types';
import thunks from './thunks';
import { ErrorState } from '../../utils/types';

const programsAdapter = createEntityAdapter<Program>({
    sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
});

export interface ProgramState {
    data: EntityState<Program>;
    listAsync: {
        loading: boolean;
        error: ErrorState | false;
    };
    findAsync: {
        loading: boolean;
        error: ErrorState | false;
    };
}

const initialState: ProgramState = {
    data: programsAdapter.getInitialState(),
    listAsync: {
        loading: false,
        error: false,
    },
    findAsync: {
        loading: false,
        error: false,
    },
};

const { fetchAll, fetchOne } = thunks.actions;

const slice = createSlice({
    name: moduleName,
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAll.request]: (state) => {
            const { listAsync } = state;
            listAsync.loading = true;
            listAsync.error = false;
        },
        [fetchAll.success]: (state, action: PayloadAction<Program[]>) => {
            const { listAsync } = state;
            listAsync.loading = false;
            listAsync.error = false;
            programsAdapter.setAll(state.data, action.payload);
        },
        [fetchAll.failure]: (state, action: PayloadAction<ErrorState>) => {
            const { listAsync } = state;
            listAsync.loading = false;
            listAsync.error = action.payload;
        },
        [fetchOne.request]: (state) => {
            const { findAsync } = state;
            findAsync.loading = true;
            findAsync.error = false;
        },
        [fetchOne.success]: (state, action: PayloadAction<Program>) => {
            const { findAsync } = state;
            findAsync.loading = false;
            findAsync.error = false;
            programsAdapter.setOne(state.data, action.payload);
        },
        [fetchOne.failure]: (state, action: PayloadAction<ErrorState>) => {
            const { findAsync } = state;
            findAsync.loading = false;
            findAsync.error = action.payload;
        },
    },
});

// TODO: memoize selectors
const selectSlice = (state: AppState) => state[moduleName];

const customSelectors = {
    selectState: selectSlice,
    selectListAsyncState: (state: AppState) => selectSlice(state).listAsync,
    selectFindAsyncState: (state: AppState) => selectSlice(state).findAsync,
};

const adapterSelectors = programsAdapter.getSelectors<AppState>(
    (state) => selectSlice(state).data,
);

const programsSlice = {
    thunks: thunks.creators,
    actions: { ...thunks.actions, ...slice.actions },
    selectors: { ...adapterSelectors, ...customSelectors },
};

export const reducer = slice.reducer;
export default programsSlice;
