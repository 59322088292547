import React from 'react';
import styles from './styles.module.scss';

interface Props {
    src?: string;
    alt?: string;
}

const ImageSession: React.FC<Props> = ({ src, alt }) => {
    return <img className={styles.image} src={src} alt={alt} />;
};

export default ImageSession;
