import {
    createEntityAdapter,
    createSlice,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit';
import { AppState } from '../..';
import { moduleName, Post } from './types';
import thunks from './thunks';
import { ErrorState } from '../../utils/types';

const postsAdapter = createEntityAdapter<Post>({
    sortComparer: (a, b) => b.publishedAt.localeCompare(a.createdAt),
});

interface AsyncState {
    loading: boolean;
    error: ErrorState | false;
}

export interface PostState {
    data: EntityState<Post>;
    listAsync: AsyncState;
    findAsync: AsyncState;
    byCategoryAsync: AsyncState;
}

const initialState: PostState = {
    data: postsAdapter.getInitialState(),
    listAsync: {
        loading: false,
        error: false,
    },
    findAsync: {
        loading: false,
        error: false,
    },
    byCategoryAsync: {
        loading: false,
        error: false,
    },
};

const { fetchAll, fetchOne, fetchByCategory } = thunks.actions;

const slice = createSlice({
    name: moduleName,
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAll.request]: (state) => {
            const { listAsync } = state;
            listAsync.loading = true;
            listAsync.error = false;
        },
        [fetchAll.success]: (state, action: PayloadAction<Post[]>) => {
            const { listAsync } = state;
            listAsync.loading = false;
            listAsync.error = false;
            postsAdapter.setAll(state.data, action.payload);
        },
        [fetchAll.failure]: (state, action: PayloadAction<ErrorState>) => {
            const { listAsync } = state;
            listAsync.loading = false;
            listAsync.error = action.payload;
        },
        [fetchOne.request]: (state) => {
            const { findAsync } = state;
            findAsync.loading = true;
            findAsync.error = false;
        },
        [fetchOne.success]: (state, action: PayloadAction<Post>) => {
            const { findAsync } = state;
            findAsync.loading = false;
            findAsync.error = false;
            postsAdapter.setOne(state.data, action.payload);
        },
        [fetchOne.failure]: (state, action: PayloadAction<ErrorState>) => {
            const { findAsync } = state;
            findAsync.loading = false;
            findAsync.error = action.payload;
        },
        [fetchByCategory.request]: (state) => {
            const { byCategoryAsync } = state;
            byCategoryAsync.loading = true;
            byCategoryAsync.error = false;
        },
        [fetchByCategory.success]: (state, action: PayloadAction<Post[]>) => {
            const { byCategoryAsync } = state;
            byCategoryAsync.loading = false;
            byCategoryAsync.error = false;
            postsAdapter.setMany(state.data, action.payload);
        },
        [fetchByCategory.failure]: (
            state,
            action: PayloadAction<ErrorState>,
        ) => {
            const { byCategoryAsync } = state;
            byCategoryAsync.loading = false;
            byCategoryAsync.error = action.payload;
        },
    },
});

// TODO: memoize selectors
const selectSlice = (state: AppState) => state[moduleName];

const adapterSelectors = postsAdapter.getSelectors<AppState>(
    (state) => selectSlice(state).data,
);

const customSelectors = {
    selectState: selectSlice,
    select6: (state: AppState) => adapterSelectors.selectAll(state).slice(0, 6),
    selectByCategory: (state: AppState, category: string) =>
        adapterSelectors
            .selectAll(state)
            .filter((post) =>
                post.categories.find(
                    (categoryFind) => categoryFind.slug === category,
                ),
            ),
    selectListAsyncState: (state: AppState) => selectSlice(state).listAsync,
    selectFindAsyncState: (state: AppState) => selectSlice(state).findAsync,
    selectByCategoryAsyncState: (state: AppState) =>
        selectSlice(state).byCategoryAsync,
};

const postsSlice = {
    thunks: thunks.creators,
    actions: { ...thunks.actions, ...slice.actions },
    selectors: { ...adapterSelectors, ...customSelectors },
};

export const reducer = slice.reducer;
export default postsSlice;
