import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConversionData, moduleName } from './types';
import cmsApi from '../../../api/cms';

const conversion = createAsyncThunk(
    `${moduleName}/conversion`,
    async (data: ConversionData) => {
        const response = await cmsApi.post('/rdstation/conversion', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const body = await response.json();

        if (response.status == 400) {
            throw new Error(body.message.errors[0]);
        }
    },
);

const actions = {
    conversion: {
        request: conversion.pending,
        success: conversion.fulfilled,
        failure: conversion.rejected,
    },
};

const creators = {
    conversion,
};

const thunks = { actions, creators };

export default thunks;
