import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import Content from '../../components/layouts/Content';
import LoadingAndError, {
    InlineLoadingAndError,
} from '../../components/LoadingAndError';
import PostList from '../../components/PostList';
import Title from '../../components/Title';
import { useAppSelector } from '../../hooks/store';
import { AppDispatch } from '../../store';
import categoriesSlice from '../../store/modules/categories';
import postsSlice from '../../store/modules/posts';
import { Post } from '../../store/modules/posts/types';
import styles from './styles.module.scss';

const BlogPage: React.FC<RouteComponentProps> = (props) => {
    const category = new URLSearchParams(props.location.search).get('category');

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (category) {
            dispatch(postsSlice.thunks.fetchByCategory(category));
        } else {
            dispatch(postsSlice.thunks.fetchAll());
        }
        dispatch(categoriesSlice.thunks.fetchAll());
    }, []);

    let posts: Post[];
    let postListState;
    if (category) {
        posts = useAppSelector((state) =>
            postsSlice.selectors.selectByCategory(state, category),
        );
        postListState = useAppSelector(
            postsSlice.selectors.selectByCategoryAsyncState,
        );
    } else {
        posts = useAppSelector(postsSlice.selectors.selectAll);
        postListState = useAppSelector(
            postsSlice.selectors.selectListAsyncState,
        );
    }

    const categories = useAppSelector(categoriesSlice.selectors.selectAll);
    const categoriesListState = useAppSelector(
        categoriesSlice.selectors.selectListAsyncState,
    );

    return (
        <Content>
            <Title className={styles.title}>
                Blog
                <InlineLoadingAndError
                    condition={posts.length !== 0}
                    loading={postListState.loading}
                    error={postListState.error}
                />
            </Title>
            <div className={styles.container}>
                <PostList
                    data={posts}
                    loading={postListState.loading}
                    error={postListState.error}
                />
                <div className={styles.sidebar}>
                    <div className={styles.section}>
                        <h2 className={styles.title}>
                            Categorias
                            <InlineLoadingAndError
                                condition={categories.length !== 0}
                                loading={categoriesListState.loading}
                                error={categoriesListState.error}
                            />
                        </h2>
                        <ul className={styles.list}>
                            <LoadingAndError
                                condition={categories.length === 0}
                                loading={categoriesListState.loading}
                                error={categoriesListState.error}
                            />
                            {categories.map((category) => (
                                <Link
                                    key={category.id}
                                    to={`/blog?category=${category.slug}`}
                                >
                                    <li className={styles.item}>
                                        {category.name}
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.section}>
                        <h2 className={styles.title}>Outras postagens</h2>
                        {posts.slice(0, 6).map((post) => (
                            <Link
                                key={post.id}
                                className={styles.otherTitles}
                                to={`/blog/${post.id}/${post.slug}`}
                            >
                                {post.title}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </Content>
    );
};

export default BlogPage;
