import React from 'react';
import styles from './styles.module.scss';

type DefaultDivProps = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

const FormGroup: React.FC<DefaultDivProps> = ({ className, ...props }) => {
    return (
        <div className={`${styles.formGroup} ${className || ''}`} {...props} />
    );
};

export default FormGroup;
