import { mapImage, mapLocalization } from '../../utils/mappers';
import { ImageResponse, Locale, LocalizationResponse } from '../../utils/types';
import { CategoryResponse, mapCategory } from '../categories/mapper';
import { Post } from './types';

interface PostResponse {
    id: string;
    title: string;
    categories: CategoryResponse[];
    content: string;
    thumbnail: ImageResponse;
    slug: string;
    created_at: string;
    updated_at: string;
    published_at: string;
    locale: Locale;
    localizations: LocalizationResponse[];
}

function mapPost(post: PostResponse): Post {
    return {
        id: Number(post.id),
        title: post.title,
        categories: post.categories.map(mapCategory),
        slug: post.slug,
        content: post.content,
        locale: post.locale,
        thumbnail: mapImage(post.thumbnail),
        createdAt: post.created_at,
        updatedAt: post.updated_at,
        publishedAt: post.published_at,
        localizations: post.localizations.map(mapLocalization),
    };
}

export { mapPost };
