import { mapLocalization } from '../../utils/mappers';
import {
    Locale,
    LocalizationResponse,
    ProgramResponse,
} from '../../utils/types';
import { mapProgram } from '../programs/mapper';
import { Motivation } from './types';

export interface MotivationResponse {
    id: string;
    name: string;
    programs: ProgramResponse[];
    created_at: string;
    updated_at: string;
    locale: Locale;
    localizations?: LocalizationResponse[];
}

function mapMotivation(data: MotivationResponse): Motivation {
    return {
        id: Number(data.id),
        name: data.name,
        programs: data.programs.map(mapProgram),
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        locale: data.locale,
        localizations: data.localizations?.map(mapLocalization),
    };
}

export { mapMotivation };
