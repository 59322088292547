import {
    mapCountry,
    mapImage,
    mapLocalization,
    mapPackage,
} from '../../utils/mappers';
import { ProgramResponse, TestimonialResponse } from '../../utils/types';
import { Program, Testimonial } from './types';

function mapTestimonial(testimonial: TestimonialResponse): Testimonial {
    return {
        id: Number(testimonial.id),
        image: mapImage(testimonial.image),
        description: testimonial.description,
    };
}

function mapProgram(program: ProgramResponse): Program {
    return {
        id: Number(program.id),
        title: program.title,
        slug: program.slug,
        thumbnail: mapImage(program.thumbnail),
        shortDescription: program.short_description,
        details: program.details,
        tickets: program.tickets,
        healthInsurance: program.health_insurance,
        accommodations: program.accommodations,
        country:
            typeof program.country === 'object'
                ? mapCountry(program.country)
                : program.country,
        package:
            typeof program.package === 'object'
                ? mapPackage(program.package)
                : program.package,
        testimonials: program.testimonials?.map(mapTestimonial),
        videoIframe: program.videoIframe,
        createdAt: program.created_at,
        updatedAt: program.updated_at,
        publishedAt: program.published_at,
        locale: program.locale,
        localizations: program.localizations?.map(mapLocalization),
    };
}

export { mapProgram, mapTestimonial };
