import { Locale, ProgramResponse } from '../../utils/types';
import { mapLocalization } from '../../utils/mappers';
import { LocalizationResponse } from '../../utils/types';
import { Home } from './types';
import { mapProgram } from '../programs/mapper';

interface HomeResponse {
    id: string;
    franchiseDescription: string;
    programs: ProgramResponse[];
    created_at: string;
    updated_at: string;
    locale: Locale;
    localizations: LocalizationResponse[];
}

function mapHome(data: HomeResponse): Home {
    return {
        id: Number(data.id),
        franchiseDescription: data.franchiseDescription,
        programs: data.programs.map(mapProgram),
        locale: data.locale,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        localizations: data.localizations.map(mapLocalization),
    };
}

export { mapHome };
