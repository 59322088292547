import { Action, Dispatch } from '@reduxjs/toolkit';
import actionUtils from './actions';

interface ThunkShape<PropType = Action> {
    request: PropType;
    success: PropType;
    failure: PropType;
}

type ThunkActions = ThunkShape;
type ActionsObject = ThunkShape<string>;

function createAsyncThunk<Payload = void, Params = void>(
    prefix: string,
    callback: (arg: Params) => Promise<Payload>,
) {
    const actions: ThunkActions = {
        request: {
            type: actionUtils.addPrefix(prefix, 'request'),
        },
        success: {
            type: actionUtils.addPrefix(prefix, 'success'),
        },
        failure: {
            type: actionUtils.addPrefix(prefix, 'failure'),
        },
    };

    function thunkCreator(arg: Params) {
        return (dispatch: Dispatch) => {
            dispatch(actions.request);
            callback(arg)
                .then((payload) => {
                    dispatch({ ...actions.success, payload });
                })
                .catch((err: Error) => {
                    if (process.env.NODE_ENV === 'development')
                        console.error(err);

                    const error = {
                        name: err.name,
                        message: err.message,
                    };
                    dispatch({ ...actions.failure, payload: error });
                });
        };
    }

    const actionsNames = Object.entries(actions).reduce<ActionsObject>(
        (reducer, [key, action]) => ({ ...reducer, [key]: action.type }),
        {} as ActionsObject,
    );

    return { actions: actionsNames, thunkCreator };
}

const thunkUtils = { createAsyncThunk };

export default thunkUtils;
