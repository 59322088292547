import React, { useEffect } from 'react';
import Title from '../../components/Title';
import Content from '../../components/layouts/Content';
import styles from './styles.module.scss';
import logo from '../../assets/images/logo/logo.svg';
import Gallery from '../../components/Gallery';
import aboutSlice from '../../store/modules/about';
import { useAppSelector } from '../../hooks/store';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import Markdown from 'react-markdown';
import LoadingAndError, {
    InlineLoadingAndError,
} from '../../components/LoadingAndError';

const AboutPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(aboutSlice.thunks.fetch());
    }, []);

    const { data, loading, error } = useAppSelector(
        aboutSlice.selectors.selectState,
    );

    return (
        <Content>
            <Title>
                Sobre
                <InlineLoadingAndError
                    condition={Boolean(data)}
                    loading={loading}
                    error={error}
                />
            </Title>
            {data ? (
                <>
                    <div className={styles.knowUsGrid}>
                        <div className={styles.content}>
                            <h1 className={styles.title}>{data.title}</h1>
                            <Markdown>{data.description}</Markdown>
                        </div>
                        <div className={styles.logoContainer}>
                            <div className={styles.sphere} />
                            <img
                                src={logo}
                                alt="Logo da My Intercâmbio"
                                className={styles.logo}
                            />
                        </div>
                    </div>
                    <div className={styles.perspectivesContainer}>
                        <div className={styles.perspective}>
                            <h1 className={styles.subtitle}>Missão</h1>
                            <Markdown
                                className={`${styles.text} ${styles.center}`}
                            >
                                {data.mission}
                            </Markdown>
                        </div>
                        <div className={styles.perspective}>
                            <h1 className={styles.subtitle}>Visão</h1>
                            <Markdown
                                className={`${styles.text} ${styles.center}`}
                            >
                                {data.vision}
                            </Markdown>
                        </div>
                        <div className={styles.perspective}>
                            <h1 className={styles.subtitle}>Valores</h1>
                            <Markdown
                                className={`${styles.text} ${styles.center}`}
                            >
                                {data.values}
                            </Markdown>
                        </div>
                    </div>
                    <Gallery
                        data={data.gallery.map((image) => ({
                            src: image.url,
                            alt: image.caption,
                        }))}
                    />
                </>
            ) : (
                <LoadingAndError loading={loading} error={error} />
            )}
        </Content>
    );
};

export default AboutPage;
