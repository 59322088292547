import { Image, Locale, Localization } from '../../utils/types';

export const moduleName = 'about';

export interface About {
    id: number;
    title: string;
    description: string;
    mission: string;
    vision: string;
    values: string;
    gallery: Image[];
    locale: Locale;
    createdAt: string;
    updatedAt: string;
    localizations: Localization[];
}
