import {
    Country,
    Image,
    Locale,
    Localization,
    Package,
} from '../../utils/types';

export const moduleName = 'programs';

export interface Testimonial {
    id: number;
    image: Image;
    description: string;
}

export interface Program {
    id: number;
    title: string;
    slug: string;
    thumbnail: Image;
    shortDescription: string;
    details: string;
    tickets: string;
    healthInsurance: string;
    accommodations: string;
    country: Country | number;
    package: Package | number;
    testimonials?: Testimonial[];
    videoIframe: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    locale: Locale;
    localizations?: Localization[];
}
