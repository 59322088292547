import { Image, Locale, Localization } from '../../utils/types';
import { Category } from '../categories/types';

export const moduleName = 'posts';

export interface Post {
    id: number;
    title: string;
    slug: string;
    categories: Category[];
    content: string;
    thumbnail: Image;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    locale: Locale;
    localizations: Localization[];
}
