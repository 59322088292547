import React from 'react';
import styles from './styles.module.scss';
import ReactInputMask, { Props as MaskInputProps } from 'react-input-mask';

type DefaultInputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>;

const Input: React.FC<DefaultInputProps> = ({ className, ...props }) => {
    return (
        <input className={`${styles.input} ${className || ''}`} {...props} />
    );
};

const InputMask: React.FC<MaskInputProps> = ({ className, ...props }) => {
    return (
        <ReactInputMask
            className={`${styles.input} ${className || ''}`}
            {...props}
        />
    );
};

export { InputMask };
export default Input;
