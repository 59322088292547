import { mapLocalization } from '../../utils/mappers';
import { Locale, LocalizationResponse } from '../../utils/types';
import { Category } from './types';

export interface CategoryResponse {
    id: string;
    name: string;
    slug: string;
    created_at: string;
    updated_at: string;
    locale: Locale;
    localizations?: LocalizationResponse[];
}

function mapCategory(category: CategoryResponse): Category {
    return {
        id: Number(category.id),
        name: category.name,
        slug: category.slug,
        createdAt: category.created_at,
        updatedAt: category.updated_at,
        locale: category.locale,
        localizations: category.localizations?.map(mapLocalization),
    };
}

export { mapCategory };
