import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import Jumbotron from '../../components/Jumbotron';
import { Country } from '../../store/utils/types';
import styles from './styles.module.scss';
import {
    IconDefinition,
    faComment,
    faDollarSign,
    faInfo,
    faPlug,
    faCity,
} from '@fortawesome/free-solid-svg-icons';
import Gallery from '../../components/Gallery';
import Markdown from 'react-markdown';
import classNames from 'classnames';
import { faClock } from '@fortawesome/free-regular-svg-icons';

interface PropertyList {
    [property: string]: {
        name: string;
        icon: IconDefinition;
    };
}

const properties: PropertyList = {
    capital: {
        name: 'Capital',
        icon: faCity,
    },
    language: {
        name: 'Idioma',
        icon: faComment,
    },
    timezone: {
        name: 'Fuso horário',
        icon: faClock,
    },
    currency: {
        name: 'Moeda local',
        icon: faDollarSign,
    },
    voltage: {
        name: 'Voltagem',
        icon: faPlug,
    },
    default: {
        name: 'Informação',
        icon: faInfo,
    },
};

function mapInfoProperty(propertyName: string) {
    return properties[propertyName] || properties.default;
}

interface Props {
    data: Country;
    className?: string;
}

const CountrySession: React.FC<Props> = ({ data, className }) => {
    const info = Object.entries({
        capital: data.capital,
        language: data.language,
        timezone: data.timezone,
        currency: data.currency,
        voltage: data.voltage,
    }).filter(([, value]) => value);

    return (
        <Jumbotron className={classNames(className)}>
            <div className={styles.header}>
                <img
                    className={styles.icon}
                    src={data.icon.url}
                    alt={data.icon.caption}
                />
                <span className={styles.moreAbout}>Mais sobre</span>
                <h1 className={styles.countryName}>{data.name}</h1>
            </div>
            <div className={styles.grid}>
                <div>
                    <h2 className={styles.title}>Descrição</h2>
                    <Markdown>{data.description}</Markdown>
                </div>
                <div className={styles.infoWrapper}>
                    {info.map(([item, value]) => {
                        const property = mapInfoProperty(item);

                        return (
                            <div key={item} className={styles.infoContainer}>
                                <Icon
                                    className={styles.icon}
                                    icon={property.icon}
                                />
                                <div className={styles.infoContent}>
                                    <div className={styles.propertyName}>
                                        {property.name}
                                    </div>
                                    <div className={styles.propertyValue}>
                                        {value}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Gallery
                className={styles.gallery}
                data={data.gallery.map((image) => ({
                    src: image.url,
                    alt: image.caption,
                }))}
            />
        </Jumbotron>
    );
};

export default CountrySession;
