import cmsApi from '../../../api/cms';
import thunkUtils from '../../utils/thunks';
import { mapProgram } from './mapper';
import { moduleName } from './types';

const fetchAll = thunkUtils.createAsyncThunk(
    `${moduleName}/fetchAll`,
    async () => {
        const response = await cmsApi.get(`/${moduleName}`);
        const body = await response.json();

        return body.map(mapProgram);
    },
);

const fetchOne = thunkUtils.createAsyncThunk(
    `${moduleName}/fetchOne`,
    async (id: string) => {
        const response = await cmsApi.get(`/${moduleName}/${id}`);
        const body = await response.json();

        return mapProgram(body);
    },
);

const actions = {
    fetchAll: fetchAll.actions,
    fetchOne: fetchOne.actions,
};

const creators = {
    fetchAll: fetchAll.thunkCreator,
    fetchOne: fetchOne.thunkCreator,
};

const thunks = { actions, creators };

export default thunks;
