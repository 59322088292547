import cmsApi from '../../../api/cms';
import thunkUtils from '../../utils/thunks';
import { mapCategory } from './mapper';
import { moduleName } from './types';

const fetchAll = thunkUtils.createAsyncThunk(moduleName, async () => {
    const response = await cmsApi.get(`/${moduleName}`);
    const body = await response.json();

    return body.map(mapCategory);
});

const actions = {
    fetchAll: fetchAll.actions,
};

const creators = {
    fetchAll: fetchAll.thunkCreator,
};

const thunks = { actions, creators };

export default thunks;
