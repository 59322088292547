import React from 'react';
import Content from '../layouts/Content';
import styles from './styles.module.scss';

type Props = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

// TODO: Remove Jumbotron and leave only Content component
const Jumbotron: React.FC<Props> = ({ className, children, ...props }) => {
    return (
        <div className={`${styles.jumbotron} ${className || ''}`} {...props}>
            <Content>{children}</Content>
        </div>
    );
};

export default Jumbotron;
