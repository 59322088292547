import React, { useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Jumbotron from '../../components/Jumbotron';
import styles from './styles.module.scss';
import quote from '../../assets/images/quote.png';
import useInterval from '../../hooks/useInterval';
import { Testimonial } from '../../store/modules/programs/types';

interface Props {
    data: Testimonial[];
}

const delay = 10000;

// TODO: Remove react-transition-group. findDOMNode is ued by the library, which
// is deprecated and generate error in Strict Mode.
const TestimonialsSession: React.FC<Props> = ({ data }) => {
    const [page, setPage] = useState(0);
    const [stop, setStop] = useState(false);

    useInterval(() => {
        if (!stop) setPage((page + 1) % data.length);
    }, delay);

    const testimonial = data[page];

    if (!testimonial) return null;

    return (
        <Jumbotron className={styles.jumbotron}>
            <div className={styles.container}>
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={testimonial.id}
                        addEndListener={(node, done) => {
                            node.addEventListener('transitionend', done, false);
                        }}
                        classNames={{
                            enter: styles.enter,
                            enterActive: styles.enterActive,
                            exit: styles.exit,
                            exitActive: styles.exitActive,
                        }}
                    >
                        <div className={styles.testimony}>
                            <div className={styles.imageContainer}>
                                <img
                                    className={styles.profileImage}
                                    src={testimonial.image.url}
                                    alt={testimonial.image.caption}
                                />
                                <img className={styles.quote} src={quote} />
                            </div>
                            <p className={styles.testimonyText}>
                                &quot;{testimonial.description}&quot;
                            </p>
                        </div>
                    </CSSTransition>
                </SwitchTransition>
                <div className={styles.pageButtonsContainer}>
                    {data.map((item, index) => (
                        <button
                            key={index}
                            className={`${styles.pageButton} ${
                                page === index && styles.active
                            }`}
                            onClick={() => {
                                setStop(true);
                                setPage(index);
                            }}
                        />
                    ))}
                </div>
            </div>
        </Jumbotron>
    );
};

export default TestimonialsSession;
