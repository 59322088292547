import React from 'react';
import styles from './styles.module.scss';
import logo from '../../../../assets/images/logo/logo-outline.svg';
import linkIcon from '../../../../assets/images/footer/link.svg';
import sacIcon from '../../../../assets/images/footer/sac.svg';
import idiomIcon from '../../../../assets/images/footer/idiom.svg';
import instagramIcon from '../../../../assets/images/social/instagram.svg';
import facebookIcon from '../../../../assets/images/social/facebook.svg';
import twitterIcon from '../../../../assets/images/social/twitter.svg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import OutlineSelect from '../../../Forms/OutlineSelect';

function Footer() {
    return (
        <>
            <div className={styles.container}>
                <img
                    className={styles.logo}
                    src={logo}
                    alt="Logo da MY intercâmbio"
                />
                <div className={styles.column}>
                    <img
                        className={styles.columnIcon}
                        src={linkIcon}
                        alt="Links"
                    />
                    <div className={styles.columnContent}>
                        <h1 className={styles.columnTitle}>Links</h1>
                        {/* TODO: Update links */}
                        <ul className={styles.linkList}>
                            <li className={styles.linkItem}>
                                <Icon
                                    className={styles.linkIcon}
                                    icon={faChevronRight}
                                />
                                <Link to="/about">Quem somos</Link>
                            </li>
                            <li className={styles.linkItem}>
                                <Icon
                                    className={styles.linkIcon}
                                    icon={faChevronRight}
                                />
                                <Link to="/">Política de privacidade</Link>
                            </li>
                            <li className={styles.linkItem}>
                                <Icon
                                    className={styles.linkIcon}
                                    icon={faChevronRight}
                                />
                                <Link to="/">Sou franqueado</Link>
                            </li>
                            <li className={styles.linkItem}>
                                <Icon
                                    className={styles.linkIcon}
                                    icon={faChevronRight}
                                />
                                <Link to="/">Material para download</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.column}>
                    <img
                        className={styles.columnIcon}
                        src={sacIcon}
                        alt="Central de atendimento"
                    />
                    <div className={styles.columnContent}>
                        <h1 className={styles.columnTitle}>
                            Central de atendimento
                        </h1>
                        <p className={styles.contactText}>
                            +55 (82) 9 9999.8888 / 9988.7777
                            <br />
                            contato@myintercambio.com.br
                        </p>
                        <div className={styles.socialIcons}>
                            <Link to="/instagram">
                                <img
                                    className={styles.icon}
                                    src={instagramIcon}
                                    alt="Instagram"
                                />
                            </Link>
                            <Link to="/facebook">
                                <img
                                    className={styles.icon}
                                    src={facebookIcon}
                                    alt="Facebook"
                                />
                            </Link>
                            <Link to="/twitter">
                                <img
                                    className={styles.icon}
                                    src={twitterIcon}
                                    alt="Twitter"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <img
                        className={styles.columnIcon}
                        src={idiomIcon}
                        alt="Idiomas"
                    />
                    <div className={styles.columnContent}>
                        <h1 className={styles.columnTitle}>Idiomas</h1>
                        <OutlineSelect
                            options={{
                                'pt-BR': 'Português',
                                en: 'English',
                                es: 'Español',
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.copyrights}>
                MY Intercâmbio {new Date().getFullYear()} | Todos os direitos
                reservados.
            </div>
        </>
    );
}

export default Footer;
