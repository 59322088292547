import { ImageResponse, Locale } from '../../utils/types';
import { mapImage, mapLocalization } from '../../utils/mappers';
import { LocalizationResponse } from '../../utils/types';
import { About } from './types';

interface AboutResponse {
    id: string;
    title: string;
    description: string;
    mission: string;
    vision: string;
    values: string;
    locale: Locale;
    created_at: string;
    updated_at: string;
    gallery: ImageResponse[];
    localizations: LocalizationResponse[];
}

function mapAbout(data: AboutResponse): About {
    return {
        id: Number(data.id),
        title: data.title,
        description: data.description,
        mission: data.mission,
        vision: data.vision,
        values: data.values,
        gallery: data.gallery.map(mapImage),
        locale: data.locale,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        localizations: data.localizations.map(mapLocalization),
    };
}

export { mapAbout };
