import { useEffect, useRef } from 'react';

function useInterval<Callback = () => void>(callback: Callback, delay: number) {
    const savedCallback = useRef<Callback>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const id = setInterval(() => {
            if (
                savedCallback.current &&
                typeof savedCallback.current === 'function'
            )
                savedCallback.current();
        }, delay);
        return () => clearInterval(id);
    }, [delay]);
}

export default useInterval;
