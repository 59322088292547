import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

type SelectDefaultProps = React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
>;

type Props = SelectDefaultProps & {
    options: { [value: string]: string };
};

const OutlineSelect: React.FC<Props> = ({
    options,
    className,
    ...restProps
}) => {
    return (
        <select className={classNames(styles.select, className)} {...restProps}>
            {Object.entries(options).map(([value, text], index) => (
                <option key={index} className={styles.option} value={value}>
                    {text}
                </option>
            ))}
        </select>
    );
};

export default OutlineSelect;
