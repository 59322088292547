import cmsApi from '../../../api/cms';
import thunkUtils from '../../utils/thunks';
import { mapPost } from './mapper';
import { moduleName } from './types';

const fetchAll = thunkUtils.createAsyncThunk(
    `${moduleName}/fetchAll`,
    async () => {
        const response = await cmsApi.get(
            `/${moduleName}?_sort=published_at:DESC`,
        );
        const body = await response.json();

        return body.map(mapPost);
    },
);

const fetchOne = thunkUtils.createAsyncThunk(
    `${moduleName}/fetchOne`,
    async (id: string) => {
        const response = await cmsApi.get(`/${moduleName}/${id}`);
        const body = await response.json();

        return mapPost(body);
    },
);

const fetchByCategory = thunkUtils.createAsyncThunk(
    `${moduleName}/fetchByCategory`,
    async (category: string) => {
        const response = await cmsApi.get(
            `/${moduleName}?categories.slug=${category}&_sort=published_at:DESC`,
        );
        const body = await response.json();

        return body.map(mapPost);
    },
);

const actions = {
    fetchAll: fetchAll.actions,
    fetchOne: fetchOne.actions,
    fetchByCategory: fetchByCategory.actions,
};

const creators = {
    fetchAll: fetchAll.thunkCreator,
    fetchOne: fetchOne.thunkCreator,
    fetchByCategory: fetchByCategory.thunkCreator,
};

const thunks = { actions, creators };

export default thunks;
