import React from 'react';
import { Link } from 'react-router-dom';
import { Post } from '../../store/modules/posts/types';
import LoadingAndError from '../LoadingAndError';
import { format } from 'date-fns';
import styles from './styles.module.scss';
import { ErrorState } from '../../store/utils/types';

interface Props {
    data: Post[];
    loading: boolean;
    error: ErrorState | false;
}

const PostList: React.FC<Props> = ({ data, loading, error }) => {
    return (
        <>
            <LoadingAndError
                condition={data.length === 0}
                loading={loading}
                error={error}
            />
            <div className={styles.container}>
                {data.map((post) => (
                    <Link
                        key={post.id}
                        className={styles.post}
                        to={`/blog/${post.id}/${post.slug}`}
                    >
                        <img
                            className={styles.thumbnail}
                            src={post.thumbnail.url}
                            alt={post.thumbnail.caption}
                        />
                        <h3 className={styles.title}>{post.title}</h3>
                        <div className={styles.publicationDate}>
                            {format(
                                new Date(post.publishedAt),
                                "'Publicado em' dd 'de' MMMM 'de' yyyy",
                            )}
                        </div>
                    </Link>
                ))}
            </div>
        </>
    );
};

export default PostList;
