import React from 'react';
import { Image } from '../../interfaces/image';
import styles from './styles.module.scss';

interface Props {
    data: Image[];
    className?: string;
}

// TODO: Add responsiveness to that component
const Gallery: React.FC<Props> = ({ data, className }) => {
    return (
        <div className={`${styles.gallery} ${className || ''}`}>
            {data.map((image, index) => (
                <img
                    key={index}
                    className={styles.image}
                    src={image.src}
                    alt={image.alt}
                />
            ))}
        </div>
    );
};

export default Gallery;
