import React from 'react';
import styles from './styles.module.scss';

type DefaultLabelProps = React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
>;

const Label: React.FC<DefaultLabelProps> = ({ className, ...props }) => {
    return (
        <label className={`${styles.label} ${className || ''}`} {...props} />
    );
};

export default Label;
