import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import DefaultLayout from '../components/layouts/DefaultLayout';

interface Props extends Omit<RouteProps, 'component'> {
    layout?: React.ElementType;
    component: React.ElementType;
}

const LayoutRoute: React.FC<Props> = ({
    layout: Layout = DefaultLayout,
    component: Component,
    ...restProps
}) => {
    return (
        <Route
            {...restProps}
            render={(props) => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
};

export default LayoutRoute;
