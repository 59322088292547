import { Locale, Localization } from '../../utils/types';
import { Program } from '../programs/types';

export const moduleName = 'home';

export interface Home {
    id: number;
    franchiseDescription: string;
    programs: Program[];
    locale: Locale;
    createdAt: string;
    updatedAt: string;
    localizations: Localization[];
}
