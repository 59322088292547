import React from 'react';
import styles from './styles.module.scss';

type DefaultButtonProps = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>;

type Props = DefaultButtonProps;

const OutlinePrimaryButton: React.FC<Props> = ({ className, ...props }) => {
    return (
        <button
            className={`${styles.outlinePrimary} ${className || ''}`}
            {...props}
        />
    );
};

export default OutlinePrimaryButton;
