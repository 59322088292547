import React from 'react';
import { ErrorState } from '../../store/utils/types';
import Error, { InlineError } from '../Error';
import Loading, { InlineLoading } from '../Loading';

interface Props {
    condition?: boolean;
    loading?: boolean;
    error?: ErrorState | boolean;
}

const InlineLoadingAndError: React.FC<Props> = ({
    condition = true,
    loading,
    error,
}) => {
    if (condition) {
        if (loading) return <InlineLoading />;
        if (error) return <InlineError />;
    }
    return null;
};

const LoadingAndError: React.FC<Props> = ({
    condition = true,
    loading,
    error,
}) => {
    if (condition) {
        if (loading) return <Loading />;
        if (error) return <Error />;
    }
    return null;
};

export { InlineLoadingAndError };
export default LoadingAndError;
