import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import Background from '../../components/Background';
import OutlinePrimaryButton from '../../components/Buttons/OutlinePrimaryButton';
import Content from '../../components/layouts/Content';
import styles from './styles.module.scss';
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';
import TestimonialsSession from '../../sessions/TestimonialsSession';
import CountrySession from '../../sessions/CountrySession';
import Jumbotron from '../../components/Jumbotron';
import ImageSession from '../../sessions/ImageSession';
import { RouteComponentProps } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import programsSlice from '../../store/modules/programs';
import { useAppSelector } from '../../hooks/store';
import Markdown from 'react-markdown';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { InlineLoadingAndError } from '../../components/LoadingAndError';

type Props = RouteComponentProps<{ id: string; slug: string }>;

const ProgramPage: React.FC<Props> = (props) => {
    // TODO: Convert slug extraction to useParams() call
    const { id } = props.match.params;

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(programsSlice.thunks.fetchOne(id));
    }, []);

    const program = useAppSelector((state) =>
        programsSlice.selectors.selectById(state, id),
    );

    const listState = useAppSelector(
        programsSlice.selectors.selectFindAsyncState,
    );

    const findState = useAppSelector(
        programsSlice.selectors.selectFindAsyncState,
    );

    if (program) {
        const { country, testimonials, package: pack } = program;
        return (
            <>
                <Background>
                    <div className={styles.background}>
                        <img
                            className={styles.backgroundImage}
                            src={program.thumbnail.url}
                            alt={program.thumbnail.caption}
                        />
                        <div className={styles.gradient} />
                    </div>
                </Background>
                <Content>
                    <div className={styles.headerGrid}>
                        <div className={styles.header}>
                            <h1 className={styles.title}>
                                {program.title}
                                <InlineLoadingAndError
                                    loading={findState.loading}
                                    error={findState.error}
                                />
                            </h1>
                            <Markdown className={styles.description}>
                                {program.shortDescription}
                            </Markdown>
                            <OutlinePrimaryButton>Comprar</OutlinePrimaryButton>
                        </div>
                        {/* TODO: add default logo if country don't exists */}
                        {typeof country === 'object' && (
                            <div className={styles.countryCard}>
                                <img
                                    className={styles.countryIcon}
                                    src={country.icon.url}
                                    alt={country.icon.caption}
                                />
                                <p>{country.shortDescription}</p>
                                <a
                                    className={styles.seeMore}
                                    href="#country-details"
                                >
                                    Ver mais
                                    <Icon
                                        className={styles.seeMoreIcon}
                                        icon={faLongArrowAltDown}
                                    />
                                </a>
                            </div>
                        )}
                    </div>
                    <h2 className={styles.subtitle}>Detalhes</h2>
                    <Markdown>{program.details}</Markdown>
                    <div className={styles.detailsGrid}>
                        <div className={styles.detailsItem}>
                            <h2 className={styles.subtitle}>Passagem</h2>
                            <Markdown>{program.tickets}</Markdown>
                        </div>
                        <div className={styles.detailsItem}>
                            <h2 className={styles.subtitle}>Seguro saúde</h2>
                            <Markdown>{program.healthInsurance}</Markdown>
                        </div>
                        <div className={styles.detailsItem}>
                            <h2 className={styles.subtitle}>Acomodações</h2>
                            <Markdown>{program.accommodations}</Markdown>
                        </div>
                    </div>
                </Content>
                {testimonials && testimonials.length > 0 && (
                    <TestimonialsSession data={testimonials} />
                )}
                {typeof country === 'object' && (
                    <CountrySession data={country} />
                )}
                {typeof pack === 'object' && (
                    <Jumbotron className={styles.programJumbotron}>
                        <div className={styles.programGrid}>
                            <div className={styles.itensContainer}>
                                <h1 className={styles.includedItensHeader}>
                                    Itens inclusos
                                </h1>
                                <Markdown className={styles.includedItens}>
                                    {pack.includedItens}
                                </Markdown>
                            </div>
                            <div className={styles.priceContainer}>
                                <div className={styles.priceHeader}>
                                    <div className={styles.installment}>
                                        <span
                                            className={
                                                styles.installmentQuantity
                                            }
                                        >
                                            {pack.installmentQuantity}x
                                        </span>
                                        <span
                                            className={styles.installmentValue}
                                        >
                                            {new Intl.NumberFormat('pt-br', {
                                                style: 'currency',
                                                currency: pack.currency,
                                            }).format(pack.installmentValue)}
                                        </span>
                                    </div>
                                    <span className={styles.priceCash}>
                                        ou{' '}
                                        {new Intl.NumberFormat('pt-br', {
                                            style: 'currency',
                                            currency: pack.currency,
                                        }).format(pack.price)}{' '}
                                        à vista
                                    </span>
                                </div>
                                <div className={styles.priceBody}>
                                    <p className={styles.priceDescription}>
                                        {pack.priceDescription}
                                    </p>
                                    <OutlinePrimaryButton
                                        className={styles.buyButton}
                                    >
                                        Comprar
                                    </OutlinePrimaryButton>
                                    <OutlinePrimaryButton
                                        className={styles.buyButton}
                                    >
                                        Comprar com consultor
                                    </OutlinePrimaryButton>
                                </div>
                            </div>
                        </div>
                    </Jumbotron>
                )}
                <ImageSession
                    src={program.thumbnail.url}
                    alt={program.thumbnail.caption}
                />
            </>
        );
    }

    if (listState.loading || findState.loading) return <Loading />;

    return <Error />;
};

export default ProgramPage;
