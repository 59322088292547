import React from 'react';

type DefaultFormProps = React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
>;

const Form: React.FC<DefaultFormProps> = (props) => {
    return <form {...props} />;
};

export default Form;
