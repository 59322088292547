import React, { useEffect } from 'react';
import Background from '../../components/Background';
import styles from './styles.module.scss';
import background from '../../assets/images/world.svg';
import Title from '../../components/Title';
import ProgramCard from '../../components/ProgramCard';
import Content from '../../components/layouts/Content';
// import { ebooks } from '../../data/ebooks';
// import EbookBanner from '../../sessions/EbookBanner';
import { PrimaryButton } from '../../components/Buttons';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import programsSlice from '../../store/modules/programs';
import { useAppSelector } from '../../hooks/store';
import LoadingAndError, {
    InlineLoadingAndError,
} from '../../components/LoadingAndError';

const ProgramListPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(programsSlice.thunks.fetchAll());
    }, []);

    const programs = useAppSelector(programsSlice.selectors.selectAll);
    const programsListState = useAppSelector(
        programsSlice.selectors.selectListAsyncState,
    );

    // TODO: Request ebook from CMS
    // const ebook = ebooks[0];

    return (
        <>
            <Content>
                <Background>
                    <div className={styles.backgroundContainer}>
                        <img className={styles.background} src={background} />
                    </div>
                </Background>
                <Title>
                    Programas
                    <InlineLoadingAndError
                        condition={programs.length !== 0}
                        loading={programsListState.loading}
                        error={programsListState.error}
                    />
                </Title>
                <LoadingAndError
                    condition={programs.length === 0}
                    loading={programsListState.loading}
                    error={programsListState.error}
                />
                <div className={styles.container}>
                    {programs.map((program) => (
                        <ProgramCard key={program.id} data={program} />
                    ))}
                </div>
                <div className={styles.rightButton}>
                    <PrimaryButton>Próxima</PrimaryButton>
                </div>
            </Content>
            {/* <EbookBanner data={ebook} /> */}
        </>
    );
};

export default ProgramListPage;
