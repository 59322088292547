import { Locale, Localization } from '../../utils/types';

export const moduleName = 'contacts';

export interface Contact {
    id: number;
    title: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    locale: Locale;
    localizations?: Localization[];
}
