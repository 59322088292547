import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../..';
import { moduleName, Franchise } from './types';
import thunks from './thunks';

export interface FranchiseState {
    data?: Franchise;
    loading: boolean;
    error: boolean;
}

const initialState: FranchiseState = {
    loading: false,
    error: false,
};

const { fetch } = thunks.actions;

const slice = createSlice({
    name: moduleName,
    initialState,
    reducers: {},
    extraReducers: {
        [fetch.request]: (state) => {
            state.loading = true;
            state.error = false;
        },
        [fetch.success]: (state, action: PayloadAction<Franchise>) => {
            state.loading = false;
            state.error = false;
            state.data = action.payload;
        },
        [fetch.failure]: (state) => {
            state.loading = false;
            state.error = true;
        },
    },
});

const selectors = {
    selectState: (state: AppState) => state[moduleName],
    selectData: (state: AppState) => state[moduleName].data,
};

const aboutSlice = {
    thunks: thunks.creators,
    actions: { ...thunks.actions, ...slice.actions },
    selectors,
};

export const reducer = slice.reducer;
export default aboutSlice;
