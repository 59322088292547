import cmsApi from '../../../api/cms';
import thunkUtils from '../../utils/thunks';
import { mapAbout } from './mapper';
import { moduleName } from './types';

const fetch = thunkUtils.createAsyncThunk(moduleName, async () => {
    const response = await cmsApi.get(`/${moduleName}`);
    return mapAbout(await response.json());
});

const actions = {
    fetch: fetch.actions,
};

const creators = {
    fetch: fetch.thunkCreator,
};

const thunks = { actions, creators };

export default thunks;
