export interface ApiConfig {
    baseUrl: string;
    options?: RequestInit;
}

type RequestFunction = (
    url: string,
    data?: unknown,
    options?: RequestInit,
) => Promise<Response>;

class Api {
    config: ApiConfig;

    constructor(config: ApiConfig) {
        this.config = config;
    }

    request: RequestFunction = async (url, data, options) => {
        const { baseUrl, options: configOptions } = this.config;

        const absoluteUrl = baseUrl + url;

        let requestInit: RequestInit | undefined;
        if (options || configOptions) {
            requestInit = {
                ...configOptions,
                ...options,
            };

            if (options?.body || configOptions?.body || data) {
                requestInit.body = JSON.stringify(
                    Object.assign({}, configOptions?.body, options?.body, data),
                );
            }
        }

        return fetch(absoluteUrl, requestInit);
    };

    get: RequestFunction = (url, data, options) => {
        return this.request(url, data, {
            ...options,
            method: 'GET',
        });
    };

    post: RequestFunction = (url, data, options) => {
        return this.request(url, data, {
            ...options,
            method: 'POST',
        });
    };

    put: RequestFunction = (url, data, options) => {
        return this.request(url, data, {
            ...options,
            method: 'PUT',
        });
    };

    patch: RequestFunction = (url, data, options) => {
        return this.request(url, data, {
            ...options,
            method: 'PATCH',
        });
    };

    delete: RequestFunction = (url, data, options) => {
        return this.request(url, data, {
            ...options,
            method: 'DELETE',
        });
    };
}

export default Api;
