import cmsApi from '../../../api/cms';
import thunkUtils from '../../utils/thunks';
import { moduleName } from './types';

interface EbookSendFormData {
    id: number;
    title: string;
    name: string;
    phone: string;
    email: string;
}

const send = thunkUtils.createAsyncThunk(
    `${moduleName}/send`,
    async (data: EbookSendFormData) => {
        const response = await cmsApi.post(
            `/${moduleName}/${data.id}/send`,
            data,
        );
        console.log(response);

        // return mapHome(await response.json());
    },
);

const actions = {
    send: send.actions,
};

const creators = {
    send: send.thunkCreator,
};

const thunks = { actions, creators };

export default thunks;
