import { Locale } from '../../utils/types';
import { mapLocalization } from '../../utils/mappers';
import { LocalizationResponse } from '../../utils/types';
import { Franchise } from './types';

interface FranchiseResponse {
    id: string;
    title: string;
    content: string;
    created_at: string;
    updated_at: string;
    locale: Locale;
    localizations: LocalizationResponse[];
}

function mapFranchise(data: FranchiseResponse): Franchise {
    return {
        id: Number(data.id),
        title: data.title,
        content: data.content,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        locale: data.locale,
        localizations: data.localizations.map(mapLocalization),
    };
}

export { mapFranchise };
