import React from 'react';
import styles from './styles.module.scss';

interface Props {
    className?: string;
}

const Title: React.FC<Props> = ({ children, className }) => {
    return <h1 className={`${styles.title} ${className || ''}`}>{children}</h1>;
};

export default Title;
