import React from 'react';
import styles from './styles.module.scss';

function InlineLoading() {
    return (
        <div className={styles.loadingEllipsis}>
            <div />
            <div />
            <div />
            <div />
        </div>
    );
}

function Loading() {
    return (
        <div className={styles.defaultContainer}>
            <div className={styles.loadingDefault}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
}

export { InlineLoading };
export default Loading;
