import React from 'react';
import { Link } from 'react-router-dom';
import { Program } from '../../store/modules/programs/types';
import styles from './styles.module.scss';

interface Props {
    data: Program;
}

const ProgramCard: React.FC<Props> = ({ data }) => {
    const { country } = data;

    return (
        <Link
            className={styles.container}
            to={`/programs/${data.id}/${data.slug}`}
        >
            <div className={styles.content}>
                <div className={styles.header}>
                    <h2 className={styles.title}>{data.title}</h2>
                    {/* TODO: add default logo if country don't exists */}
                    {typeof country === 'object' && (
                        <img
                            src={country.icon.url}
                            alt={country.icon.caption}
                            className={styles.icon}
                        />
                    )}
                </div>
                <p className={styles.description}>{data.shortDescription}</p>
            </div>
            <div className={styles.thumbnailContainer}>
                <img
                    src={data.thumbnail.url}
                    alt={data.thumbnail.caption}
                    className={styles.thumbnail}
                />
                <div className={styles.thumbnailCloud} />
            </div>
        </Link>
    );
};

export default ProgramCard;
