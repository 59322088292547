import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '../..';
import { moduleName } from './types';
import thunks from './thunks';

export interface EbookState {
    sendStatus: 'idle' | 'in_progress' | 'success' | 'failed';
}

const initialState: EbookState = {
    sendStatus: 'idle',
};

const { send } = thunks.actions;

const slice = createSlice({
    name: moduleName,
    initialState,
    reducers: {},
    extraReducers: {
        [send.request]: (state) => {
            state.sendStatus = 'in_progress';
        },
        [send.success]: (state) => {
            state.sendStatus = 'success';
        },
        [send.failure]: (state) => {
            state.sendStatus = 'failed';
        },
    },
});

const selectors = {
    selectState: (state: AppState) => state[moduleName],
    selectSendStatus: (state: AppState) => state[moduleName].sendStatus,
};

const ebooksSlice = {
    thunks: thunks.creators,
    actions: { ...thunks.actions, ...slice.actions },
    selectors,
};

export const reducer = slice.reducer;
export default ebooksSlice;
