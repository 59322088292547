import React from 'react';
import styles from './styles.module.scss';

type DefaultInputProps = React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
>;

const Input: React.FC<DefaultInputProps> = ({ className, ...props }) => {
    return (
        <textarea
            className={`${styles.textarea} ${className || ''}`}
            {...props}
        />
    );
};

export default Input;
