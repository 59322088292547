import React from 'react';
import styles from './styles.module.scss';

type DefaultButtonProps = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> & { loading?: boolean };

const SubmitButton: React.FC<DefaultButtonProps> = ({
    className,
    loading,
    children,
    ...props
}) => {
    return (
        <button
            className={`${styles.submitButton} ${className || ''}`}
            {...props}
        >
            {children}
            {loading && (
                <div className={styles.loaderContainer}>
                    <div className={styles.loader} />
                </div>
            )}
        </button>
    );
};

export default SubmitButton;
