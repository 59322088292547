import React from 'react';
import styles from './styles.module.scss';

type DefaultButtonProps = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>;

type Props = DefaultButtonProps;

const PrimaryButton: React.FC<Props> = ({ className, ...props }) => {
    return (
        <button className={`${styles.primary} ${className || ''}`} {...props} />
    );
};

export default PrimaryButton;
